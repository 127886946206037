import React from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import RichText from "../components/richText";

export default function Testimonial({ quote, author, className }) {
  return (
    <TestimonialWrapper className={className}>
      <RichText>{documentToReactComponents(quote.json)}</RichText>
      <TestimonialFooter>&mdash; {author}</TestimonialFooter>
    </TestimonialWrapper>
  );
}

const TestimonialWrapper = styled.blockquote`
  margin-top: 1rem;
  margin-left: 0;
  padding-left: 1em;
  border-left: 0.25rem solid lightgrey;

  p:first-child {
    margin-top: 0;
  }
`;

const TestimonialFooter = styled.footer`
  margin-top: 0.5rem;
`;
