import React from "react";
import styled from "styled-components";
import { Container } from "./container";
import Breadcrumb from "./breadcrumb";

const Hero = ({ children, breadcrumbs = null }) => (
  <Wrapper>
    <Container>
      {breadcrumbs && <Breadcrumb parts={breadcrumbs} />}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Container>
  </Wrapper>
);

export default Hero;

const Wrapper = styled.div`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const ChildrenWrapper = styled.div`
  padding-top: 4rem;
`;
