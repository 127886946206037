import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import Testimonial from "../components/testimonial";
import { subHeading } from "../tokens/typography";

function ServiceTemplate({ data, pageContext }) {
  const { title, description, image, testimonials } = data.contentfulService;
  const { json } = description;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    {
      name: pageContext.parentPage.title,
      href: `/${pageContext.parentPage.slug}/`,
    },
    { name: title },
  ];
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle id="mainContent">{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <HeroImg fluid={image.fluid} />
      <MaxWidthBox>
        <RichText>{documentToReactComponents(json)}</RichText>
        {testimonials && testimonials.length > 0 && (
          <>
            <TestimonialHeading>Testimonial</TestimonialHeading>
            {testimonials.map(({ id, quote, author }) => (
              <Testimonial key={id} quote={quote} author={author} />
            ))}
          </>
        )}
      </MaxWidthBox>
    </Layout>
  );
}

export default ServiceTemplate;

export const servicesQuery = graphql`
  query ServiceById($id: String!) {
    contentfulService(id: { eq: $id }) {
      title
      slug
      description {
        json
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      testimonials {
        id
        quote {
          json
        }
        author
      }
    }
  }
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const HeroImg = styled(Img)`
  margin-right: -1rem;
  margin-bottom: 1.5rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  @media (min-width: 50em) {
    margin-right: -2rem;
    margin-left: -3rem;
  }
`;

const TestimonialHeading = styled.h2`
  ${subHeading};
`;
